Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.drawerMenu = [{pageName:'Terms & Conditions',pagePath:'Termsandconditions'},{pageName:'Privacy Policy',pagePath:'PrivacyPolicy'},{pageName:"FAQ's",pagePath:'Settings2'},{pageName:'Guides & Information',pagePath:'Settings2'},{pageName:'Referral Program',pagePath:'Settings2'},{pageName:'Legal',pagePath:'Settings2'},{pageName:'Help',pagePath:'Settings2'}]
  exports.drawerMenuScreenManager = [{pageName:'Settings',pagePath:'ManagerSetting', noArrow: true},{pageName:'Help',pagePath:'ManagerHelp', noArrow: true}, {pageName:'Logout',pagePath:'Logout', noArrow: true}]

  exports.headerHelpTxt = "HELP";
  exports.headerSettingTxt = "SETTING";
  exports.headerChangePassword = "CHANGE PASSWORD";
  exports.headerChangeEmail = "CHANGE EMAIL";
  exports.headerUpdateProfile = "EDIT PROFILE";

  exports.validationApiContentType = "application/json";
  exports.logoutApiEndPoint = '/account_block/accounts/logout'
  exports.logoutApiMethod = 'DELETE'

  exports.logoutAlert={
    title:'Are you sure you want to log out of your account?',
    text:'',
    buttons:[
      {name:'Cancel',type:'text',action:'cancel',class:'textBtn'},
      {name:'Logout',type:'contained',action:'logout',class:'conatinedBtn'}
    ]
  }

  exports.httpPutMethod = "PUT";
  exports.httpGetMethod = "GET";

  exports.updateUserProfileApiEndPoint = '/account_block/screen_managers/update_profile'
  exports.getUserProfileApiEndPoint = '/account_block/accounts/get_profile'


  // Customizable Area End