import React from 'react';
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container,
	Box,
	Button,
	InputLabel,
	InputAdornment,
	IconButton, styled, AppBar,
	Grid,
	OutlinedInput,
} from '@material-ui/core';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {CheckCircle } from "@material-ui/icons";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import ForgotPasswordController from "../../../blocks/forgot-password/src/ForgotPasswordController";
import { getStorageData } from "../../../framework/src/Utilities";

import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../../framework/src/RunEngine';

export const configJSON = require("../config");

const theme = createTheme({
	palette: {
	  primary: {
		main: "#fff",
		contrastText: "#fff",
	  },
	},
	typography: {
	  h1: {
		fontWeight: 'bold',
		color: 'green'
	  },
	  h5: {
		fontWeight: 600,
		color: "red"
	  },
	  h6: {
		fontWeight: 500,
	  },
	  subtitle1: {
		margin: "20px 0px",
	  },
	  body2: {
		fontSize: '0.7em',
	  },
	},
	overrides: {
	  MuiSvgIcon: {
		root: {
		  '&.passwordIcons': {
			width: "18px",
			height: "18px",
			color:'#94A3B8',
		  }
		},
	  },
	  MuiInputBase: {
		input: {
		  padding: '10.5px',
		  "&::placeholder": {
			fontSize: "1em",
		  },
		  "&:focus": {
  
			borderRadius: '2px',
		  },
		},
  
	  },
	  MuiOutlinedInput: {
		root: {
		  borderRadius: '8px',
		  outline: "1px solid #CBD5E1",
		  height: '3.6em',
		  fontSize: '1em'
		},
		input: {
		  height: '100%',
		  padding: '0px 10.5px',
		  fontSize: '1em'
		}
	  },
	  MuiDrawer: {
			root: {
				'&.noInternetAlert > .MuiPaper-root': {
					borderTopRightRadius: '25px',
					backgroundColor:'black'
				},
			}
	  },
	  MuiButton: {
			root: {
				fontSize: '1em',
				textTransform: 'none',
				borderRadius: '8px',
				margin: '12 0 0',
				'&.proceedBtn': {
					color:'#1E293B',
					background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
					padding: '12',
					fontWeight: '700',
				},
			}
	  }
	},
});

const styles = (theme: Theme) =>
	createStyles({
		root: {
			color: '#ffffff', // Text color
			height: '100vh',
		},
		title: {
			marginTop: theme.spacing(8),
			paddingLeft: theme.spacing(2),
			paddingBottom: "10px",
			fontWeight: 'bold',
		},
		list: {
			padding: theme.spacing(2),
			color: '#CBD5E1'
		},
		listItem: {
			'&:not(:last-child)': {
				marginBottom: theme.spacing(1),
			},
		},
		listItemIcon: {
			color: '#CBD5E1',
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		listItemRightIcon: {
			color: '#CBD5E1',
			display: 'flex',
  		justifyContent: 'flex-end',

		}
});

class ChangePassword extends ForgotPasswordController {

  updatePasswordApiID: string = '';

	constructor(props: any) {
		super(props);
	}

	handleBack = () => {
		this.props.navigation?.goBack();
	}

	handlePage=(pageName:string)=>{
		this.props.navigation.navigate(pageName)
	}

	handleUpdatePassword = async () => {
		if(!this.state.newPassword.length || !this.state.confirmPassword) {
			this.UpdateNullPasswordError();
		}
		const token = await getStorageData("token");
		const header = {
      "Content-Type": "application/json",
      token
    };

		const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
		requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
		requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), '/account_block/screen_managers/update_password');
		requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PUT');
		requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({
			old_password: this.state.passwordRules,
			new_password: this.state.newPassword,
			confirm_password: this.state.confirmPassword
		}));

    	runEngine.sendMessage(requestMessage.id, requestMessage);
		this.updatePasswordApiID = requestMessage.messageId;
		
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		if(apiRequestCallId == this.updatePasswordApiID){
			this.handleUpdatePasswordResponse(responseJson)
		}
	}

	UpdateNullPasswordError = () => {
		this.setState({isEmailValid: true});
	}

	handleUpdatePasswordResponse=(responseJson:any)=>{
		if(responseJson.errors){
			this.setState({errorMsg:responseJson.errors[0].message})
		}
		else{
			this.setState({passwordUpdated:true})
			this.props.handleMessage && this.props.handleMessage(responseJson?.message || '')
			this.props.handlePageState && this.props.handlePageState('');
		}
	}

	isOldSame = () => {
		return this.state.newPassword.length && this.state.passwordRules.length && this.state.newPassword === this.state.passwordRules
	}

	render() {
		return (
			<Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
				<ThemeProvider theme={theme}>
					<div style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',position:'fixed',backgroundRepeat: 'no-repeat',backgroundSize: '100% 100%',width:'100vw'}}>
						<Container maxWidth={"sm"} style={{ height: '100vh',overflow:'scroll' }}>
						<Box sx={webStyle.mainWrapper}>
							<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', marginTop:'20px'}}>
							
								<form style={webStyle.form}>
									<InputLabel style={{ color:'#E2E8F0',fontSize: '1em', fontWeight: '700', margin: '18 0 8' }}>
										Old Password
									</InputLabel>
									<OutlinedInput
										fullWidth
										required
										name="passwordRules"
										value={this.state.passwordRules}
										type={this.state.showNewPass ? "password" : 'text'}
										id="passwordRules"
										placeholder="Your old password"
										style={{ margin: '0px 0px 5px', color: '#CBD5E1',background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}
										data-test-id='newPasswordInput'
										onFocus={() => this.validatePassword('focus')}
										onBlur={() => this.validatePassword('blur')}
										onChange={(event) => this.setState({passwordRules: event.target.value})}
										endAdornment={
											<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => this.setShowPassword('new')}
												edge="end"
											>
												{!this.state.showNewPass ? (
												<Visibility className="passwordIcons" />
												) : (
												<VisibilityOff className="passwordIcons" />
												)}
											</IconButton>
											</InputAdornment>
										}
									/>
									{!this.state.passwordRules.length && this.state.isEmailValid && <span style={{color: 'red'}}>Old password is required</span>}
									<InputLabel style={{ color:'#E2E8F0',fontSize: '1em', fontWeight: '700', margin: '18 0 8' }}>
										New Password
									</InputLabel>
									<OutlinedInput
										fullWidth
										required
										name="newPassword"
										value={this.state.newPassword}
										type={this.state.showNewPass ? "password" : 'text'}
										id="newPassword"
										placeholder="Your new password"
										style={{ margin: '0px 0px 5px', color: '#CBD5E1',background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}
										data-test-id='newPasswordInput'
										onFocus={() => this.validatePassword('focus')}
										onBlur={() => this.validatePassword('blur')}
										onChange={(event) => this.setNewPassword(event.target.value)}
										endAdornment={
											<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => this.setShowPassword('new')}
												edge="end"
											>
												{!this.state.showNewPass ? (
												<Visibility className="passwordIcons" />
												) : (
												<VisibilityOff className="passwordIcons" />
												)}
											</IconButton>
											</InputAdornment>
										}
									/>
									{this.isOldSame() ? <span style={{color: 'red'}}>Old password and new password can not be same</span> : ""}
									{!this.state.newPassword.length && this.state.isEmailValid && <span style={{color: 'red'}}>Password is required</span>}
									<InputLabel style={{ color:'#E2E8F0',fontSize: '1em', fontWeight: '700', margin: '24 0 8' }}>
										Confirm Password
									</InputLabel>
									<OutlinedInput
										fullWidth
										name="confirmPassword"
										value={this.state.confirmPassword}
										type={this.state.showConfirmPass ? "password" : 'text'}
										id="confirmPassword"
										placeholder="Confirm your new password"

										style={{ margin: '0px 0px 5px', color: '#CBD5E1',background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)' }}
										data-test-id='confirmPasswordInput'
										onChange={(event) => this.setConfirmPassword(event.target.value)}
										required
										endAdornment={
											<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => this.setShowPassword('confirm')}
												edge="end"
											>
												{!this.state.showConfirmPass ? (
												<Visibility className="passwordIcons" />
												) : (
												<VisibilityOff className="passwordIcons" />
												)}
											</IconButton>
											</InputAdornment>
										}
									/>
									{!this.state.confirmPassword.length && this.state.isEmailValid && <span style={{color: 'red'}}>Password is required</span>}
									<Grid style={{ margin: '34px 0px' }} item>
										<Box sx={{ width: "100%", minHeight: "60px" }}>
										<Box sx={{ fontSize: '1em', color: '#FFFFFF', fontWeight: '400', margin: '16 0 8' }}>Your password must contain</Box>
											{this.state.passwordErrors.map((item: any, index: number) => {
												return (
													<Box
														sx={{
															marginTop: "8px",
															marginBottom: "8px",
															display: "flex",
															flexWrap: "no-wrap",
															flexDirection: "row",
															alignItems: "center",
															fontFamily: `Inter`,
														}}
														key={index}>
														<Box sx={{ marginRight: "5px" }}>
															{item.isValid ?
																<CheckCircle fontSize="small" style={{ color: '#34D399', }} /> 
																: !this.state.showPasswordValidation ?
																<CheckCircle fontSize="small" style={{ color: '#94A3B8' }} /> 
																:
																<RemoveCircleIcon fontSize="small" style={{ color: "#DC2626" }} />
															}
														</Box>
														<Box sx={{ color: '#FFFFFF', fontSize: '0.9em' }}>{item.msg}</Box>
													</Box>
												);
											})}
										</Box>
									</Grid>

									{this.state.errorMsg &&
										<Box sx={webStyle.alertBox} className="alert-box">
										{this.state.errorMsg}
										</Box>
									}
									
									<Button
										data-test-id="submitBtn"
										className="proceedBtn"
										variant="contained"
										style={webStyle.submit}
										fullWidth
										onClick={this.handleUpdatePassword}
									>
										Change Password
									</Button>
								</form>
							</Box>
						</Box>
						</Container>
					</div>
				</ThemeProvider> 

				{/* <NewPassword navigation={this.props.navigation} /> */}
			</Box>
		);
	}
}

export default withStyles(styles)(ChangePassword);

const StyledHeader = styled(AppBar)({
	width: '100%',
	display: "flex",
	flexDirection: 'row',
	justifyContent: "space-between",
	background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
	boxShadow: "none",
	alignItems: "centner",
	padding: "20px 16px 16px 20px",
	color: "#FFF",
	"& .pageTitle": {
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "22px",
	},
	borderBottom: "1px solid #64748B",
});

const webStyle = {
  logoe: {
    filter: 'invert(1)',
    marginTop: "80px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    paddingBottom: "30px",
    height: '80%',
    alignItems: 'center'
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    // margin: theme.spacing(3, 0, 5),
    background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
    color:'#1E293B',
  },
  alertBox: {
    backgroundColor: '#FEE2E2',
    padding: '8px 12px',

    borderLeft: '4px solid red',
    borderRadius: '4px',
    fontSize: '0.9em',

    margin: '12px 0',
    width: '90%',
    color: 'red',
  }
};
